@spin-prefix-cls: ~"@{css-prefix}spin";
@spin-dot-size-small: 12px;
@spin-dot-size: 20px;
@spin-dot-size-large: 32px;

.@{spin-prefix-cls} {
    color: @primary-color;
    vertical-align: middle;
    text-align: center;

    &-dot {
        position: relative;
        display: block;
        border-radius: 50%;
        background-color: @primary-color;
        .square(@spin-dot-size);
        animation: ani-spin-bounce 1s 0s ease-in-out infinite;
    }

    &-large &-dot {
        .square(@spin-dot-size-large);
    }

    &-small &-dot {
        .square(@spin-dot-size-small);
    }

    &-fix {
        position: absolute;
        top: 0;
        left: 0;
        z-index: @zindex-spin;
        .square(100%);
        background-color: rgba(255,255,255,.9);
    }
    &-fullscreen{
        z-index: @zindex-spin-fullscreen;
        &-wrapper{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &-fix &-main {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &-fix &-dot {
        display: inline-block;
    }

    &-text,
    &-show-text &-dot {
        display: none;
    }

    &-show-text &-text {
        display: block;
    }
}

// use in Table loading, Table right border is not included in .ivu-table-wrapper, so fix it
.@{table-prefix-cls}-wrapper{
    > .@{spin-prefix-cls}-fix{
        border: none;
    }
}
.@{table-prefix-cls}-wrapper-with-border{
    > .@{spin-prefix-cls}-fix{
        border: 1px solid @border-color-base;
        border-top: 0;
        border-left: 0;
    }
}

@keyframes ani-spin-bounce {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}
