
// iView styles
@import '~view-design/src/styles/mixins/index';
@import '~view-design/src/styles/common/index';
@import '~view-design/src/styles/animation/index';
@import '~view-design/src/styles/components/index';



@import "variables";
@import "utils.less";

body {
    font-family: @font-family-body, "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    width: 100%;
}

// Button
.ivu-btn {
    height: auto;
    min-height: 40px;
    font-size: 16px;
}

// Carousel
.ivu-carousel-dots {
    li button {
        background: #fff !important;
    }
}

// Tag
.ivu-tag {
    cursor: default;
}

// Header
.ivu-menu-horizontal {
    height: @layout-header-height;
    line-height: @layout-header-height;
}

.ivu-layout-header {
    line-height: initial !important;
}

@media @mobile {
    #header {
        line-height: initial !important;
    }

    .heading-display { 
        line-height: 50px;
    }
}

// Badges
.ivu-badge-count {
    font-family: inherit;
}

// Pagination
.ivu-page {
    display: flex;
    justify-content: center;
}

.ivu-carousel-dots li button {
    background: #fff !important;
}

// Checkbox
.ivu-checkbox {
    margin-right: 5px;
}
.ivu-checkbox-wrapper.ivu-checkbox-large {
    font-size: 14px;
}

// inputs 
.ivu-input-large {
    font-size: @font-size-small;
}

// Error messages 
.ivu-form-item-error-tip {
    font-size: 12px;
}

.ivu-page-item a {
    font-family: inherit;
    font-size: 14px;
}

// Tabs
.ivu-tabs-nav-container {
    font-size: 18px;
    //border-bottom: 2px solid @border-color-base;
}
.ivu-tabs {
    
    .ivu-tabs-nav-scroll {
        display: flex;
        justify-content: center;
    }    
    
    .ivu-tabs-content {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }    
}

@import "results";

// TYPOGRAPHY

h1, h2, h3, h4, h5, h6 {
    color: @title-color;
    font-family: @font-family-display;
}

h1 {
    font-size: 1.6em;
    margin-bottom: 30px;
}

h2 {
    margin-bottom: 20px;
}

.paragraph {
    p {
        margin-bottom: 30px;
    }
}

.image[lazy=loading],
img[lazy=loading]{
    background-size: 40px !important;
    background-repeat: no-repeat !important;
}
