@avatar-prefix-cls: ~"@{css-prefix}avatar";

.@{avatar-prefix-cls} {
    display: inline-block;
    text-align: center;
    background: @avatar-bg;
    color: @avatar-color;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;

    &-image{
        background: transparent;
    }

    .ivu-icon{
        position: relative;
        top: -1px;
    }

    .avatar-size(@avatar-size-base, @avatar-font-size-base);

    &-large {
        .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
        .ivu-icon{
            position: relative;
            top: -2px;
        }
    }

    &-small {
        .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
    }

    &-square {
        border-radius: @avatar-border-radius;
    }

    & > img {
        width: 100%;
        height: 100%;
    }
}

.avatar-size(@size, @font-size) {
    width: @size;
    height: @size;
    line-height: @size;
    border-radius: 50%;

    //& > * {
    //    line-height: @size;
    //}

    &.@{avatar-prefix-cls}-icon {
        font-size: @font-size;
    }
}
