@slider-prefix-cls: ~"@{css-prefix}slider";

.@{slider-prefix-cls} {
    line-height: normal;
    &-wrap{
        width: 100%;
        height: @slider-height;
        margin: @slider-margin;
        background-color: @border-color-split;
        border-radius: @btn-border-radius-small;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
    }

    &-button-wrap{
        .square(@slider-button-wrap-size);
        text-align: center;
        background-color: transparent;
        position: absolute;
        top: @slider-button-wrap-offset;
        transform: translateX(-50%);

        .@{tooltip-prefix-cls} {
            display: block;
            user-select: none;
        }
    }

    &-button{
        width: 12px;
        height: 12px;
        border: 2px solid @slider-color;
        border-radius: 50%;
        background-color: #fff;
        transition: all @transition-time linear;
        outline: 0;

        &:focus,
        &:hover,
        &-dragging
        {
            border-color: @primary-color;
            transform: scale(1.5);
        }

        &:hover{
            cursor: grab;
        }
        &-dragging,
        &-dragging:hover
        {
            cursor: grabbing;
        }
    }

    &-bar{
        height: @slider-height;
        background: @slider-color;
        border-radius: @btn-border-radius-small;
        position: absolute;
    }

    &-stop{
        position: absolute;
        .square(@slider-height);
        border-radius: 50%;
        background-color: #fff;
        transform: translateX(-50%);
    }

    &-marks{
        top: 0;
        left: 12px;
        width: 18px;
        height: 100%;

        &-item{
            position: absolute;
            transform: translateX(-50%);
            font-size: @font-size-base;
            color: @subsidiary-color;
            margin-top: 15px;
        }
    }
}

.@{slider-prefix-cls}-disabled{
    cursor: @cursor-disabled;

    .@{slider-prefix-cls}-wrap{
        background-color: @slider-disabled-color;
        cursor: @cursor-disabled;
    }
    .@{slider-prefix-cls}-bar{
        background-color: @slider-disabled-color;
    }

    .@{slider-prefix-cls}-button{
        border-color: @slider-disabled-color;

        &:hover,
        &-dragging
        {
            border-color: @slider-disabled-color;
        }
        &:hover{
            cursor: @cursor-disabled;
        }
        &-dragging,
        &-dragging:hover
        {
            cursor: @cursor-disabled;
        }
    }
}

.@{slider-prefix-cls}-input{
    .@{slider-prefix-cls}-wrap{
        width: auto;
        margin-right: 100px;
    }

    .@{input-number-prefix-cls}{
        float: right;
        margin-top: -14px;
    }
}
