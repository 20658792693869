@message-prefix-cls: ~"@{css-prefix}message";
@icon-prefix-cls: ~"@{css-prefix}icon";

.@{message-prefix-cls} {
    font-size: @font-size-base;
    position: fixed;
    z-index: @zindex-message;
    width: 100%;
    top: 16px;
    left: 0;
    pointer-events: none;

    &-notice {
        padding: 8px;
        text-align: center;
        transition: height @animation-time @ease-in-out, padding @animation-time @ease-in-out;

        &:first-child {
          margin-top: -8px;
        }

        &-close {
            position: absolute;
            right: 4px;
            top: 10px;
            color: #999;
            outline: none;

            i.@{icon-prefix-cls}{
                .close-base(-3px);
            }
        }
    }

    &-notice-content {
        display: inline-block;
        pointer-events: all;
        padding: 8px 16px;
        //border: 1px solid @border-color-split;
        border-radius: @border-radius-small;
        box-shadow: @shadow-base;
        background: #fff;
        position: relative;
        &-text{
            display: inline-block;
        }
    }

    &-notice-closable{
        .@{message-prefix-cls}-notice-content-text{
            padding-right: 32px;
        }
    }

    &-success .@{icon-prefix-cls} {
        color: @success-color;
    }

    &-error .@{icon-prefix-cls} {
        color: @error-color;
    }

    &-warning .@{icon-prefix-cls} {
        color: @warning-color;
    }

    &-info .@{icon-prefix-cls},
    &-loading .@{icon-prefix-cls} {
        color: @primary-color;
    }

    .@{icon-prefix-cls} {
        margin-right: 4px;
        font-size: @font-size-large;
        vertical-align: middle;
    }
    &-custom-content{
        span{
            vertical-align: middle;
        }
    }

    &-notice-with-background{
        .@{message-prefix-cls}-notice-content{
            &-background{
                box-shadow: none;
            }
            &-info{
                background: ~`colorPalette("@{primary-color}", 1)`;
                color: ~`colorPalette("@{primary-color}", 6)`;
                border: 1px solid ~`colorPalette("@{primary-color}", 2)`;
            }
            &-success{
                background: ~`colorPalette("@{success-color}", 1)`;
                color: ~`colorPalette("@{success-color}", 6)`;
                border: 1px solid ~`colorPalette("@{success-color}", 2)`;
            }
            &-warning{
                background: ~`colorPalette("@{warning-color}", 1)`;
                color: ~`colorPalette("@{warning-color}", 6)`;
                border: 1px solid ~`colorPalette("@{warning-color}", 2)`;
            }
            &-error{
                background: ~`colorPalette("@{error-color}", 1)`;
                color: ~`colorPalette("@{error-color}", 6)`;
                border: 1px solid ~`colorPalette("@{error-color}", 2)`;
            }
        }
    }
}
