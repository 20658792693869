.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &::-webkit-scrollbar {
        display: none;
    }
}

.text-center {
    text-align: center;
}

.heading-display {
    font-family: @font-family-display;
    font-weight: bold;
    line-height: 60px;
}
