@progress-prefix-cls: ~"@{css-prefix}progress";

.@{progress-prefix-cls} {
    display: inline-block;

    width: 100%;
    &-vertical {
        height: 100%;
        width: auto;
    }

    font-size: @font-size-small;
    position: relative;

    &-outer {
        display: inline-block;
        width: 100%;
        margin-right: 0;
        padding-right: 0;

        .@{progress-prefix-cls}-show-info & {
            padding-right: 55px;
            margin-right: -55px;
        }
    }
    &-vertical &-outer {
        height: 100%;
        width: auto;
    }

    &-inner {
        display: inline-block;
        width: 100%;
        background-color: #f3f3f3;
        border-radius: 100px;
        vertical-align: middle;
        position: relative;
        &-text{
            display: inline-block;
            vertical-align: middle;
            color: #fff;
            font-size: 12px;
            margin: 0 6px;
        }
    }
    &-vertical &-inner {
        height: 100%;
        width: auto;

        & > *, &:after {
            display: inline-block;
            vertical-align: bottom;
        }

        &:after {
            content: '';
            height: 100%;
        }
    }

    &-bg {
        text-align: right;
        border-radius: 100px;
        background-color: @primary-color;
        transition: all @transition-time linear;
        position: relative;
        &:after{
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    &-success-bg{
        border-radius: 100px;
        background-color: @success-color;
        transition: all @transition-time linear;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-text {
        display: inline-block;
        margin-left: 5px;
        text-align: left;
        font-size: 1em;
        vertical-align: middle;
        color: @subsidiary-color;
    }

    &-active {
        .@{progress-prefix-cls}-bg:before {
            content: '';
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #fff;
            border-radius: 10px;
            animation: ivu-progress-active 2s @ease-in-out infinite;
        }
    }

    &-vertical&-active{
        .@{progress-prefix-cls}-bg:before {
            top: auto;
            animation: ivu-progress-active-vertical 2s @ease-in-out infinite;
        }
    }

    &-wrong {
        .@{progress-prefix-cls}-bg {
            background-color: @error-color;
        }
        .@{progress-prefix-cls}-text {
            color: @error-color;
        }
    }

    &-success {
        .@{progress-prefix-cls}-bg {
            background-color: @success-color;
        }
        .@{progress-prefix-cls}-text {
            color: @success-color;
        }
    }
}

@keyframes ivu-progress-active {
    0% {
        opacity: .3;
        width: 0;
    }
    100% {
        opacity: 0;
        width: 100%;
    }
}

@keyframes ivu-progress-active-vertical {
    0% {
        opacity: .3;
        height: 0;
    }
    100% {
        opacity: 0;
        height: 100%;
    }
}
