.main-layout {
    height: calc(100vh ~"-" @layout-header-height);
    
    
    .sider-filter {
        height: calc(~"100vh - 60px");
        background: #fff;
    
        .sider-filter-wrapper {
            height: calc(~"100vh - 60px");
            overflow: auto;
            overflow-y: scroll; /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;
            padding: 30px 15px 70px 30px;
            
            .map-toggle {
                padding-bottom: 20px;
                display: flex;
                align-items: center;
    
                .ivu-switch-inner {
                    line-height: 15px;
                }
            }
            
            .reset-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: white;
                padding: 20px;
            }
        }
        
        .ivu-collapse-simple {
            border: 0;
        }
        
        .ivu-collapse-header {
            height: auto;
            padding: 15px 0;
            line-height: 20px;
            
            .heading {
                font-weight: bold;
                font-size: 16px;
                display: flex;
                align-items: center;
            }
            
            .description {
                display: block;
                font-size: 12px;
                color: grey;
                padding-right: 20px;
            }
            
            > i {
                float: right;
                line-height: 20px;
                margin: 0;
                font-size: 16px;
            }
        }

        .ivu-collapse-content {
            padding: 0;
            
            label {
                margin: 0;
                display: block;
                padding-bottom: 10px;
            }
        }
                
        &.show-mobile {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            width: 100% !important;
            max-width: 100% !important;
            height: 100%;
            transition: none;
    
            .sider-filter-wrapper {
                height: 100%;
                padding: 20px;
            }
            
            #mobile-filter-toggle button i:before {
                content: "\F375"; // check mark
            }
        }
    }
    
    .content {
        height: calc(100vh ~"-" @layout-header-height);
        max-width: 800px;
        padding: 30px 30px 60px 15px;
        background: #fff;
        margin: 0 auto;
        
        @media @mobile {
            h1 {
                font-size: 16px;
            }
        }
    }
    
    .sider-map {
        height: calc(100vh ~"-" @layout-header-height);
        background: #f1f1f1;
        max-width: 100% !important;
        flex: 1 !important;
        border-top: 1px solid @border-color-light;
        
        &.ivu-layout-sider-collapsed {
            flex: 0 !important;
        }
    }
    
    #mobile-filter-toggle {
        display: none;
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 800;
        padding: 20px;
        
        button {
            box-shadow: @main-box-shadow;
            width: 50px;
            height: 50px;
        }
    }

    #mobile-map-toggle {
        display: none;

        .ivu-btn {
            min-width: 38px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
            border: none;
            border-radius: 3px;
        }
    }

    .provider-suggestion {
        padding-bottom: 30px;
    }

    #listing-container {
        h4 {
            color: @text-color;
        }
    }

    @media @desktop-m-up {
        #listing-container, .sider-filter, .sider-map {
            border-top: 1px solid @border-color-light;
        }
    }

    @media @mobile-alt {
        #mobile-filter-toggle {
            display: block;
        }
        #listing-container {
            h1, p {
                text-align: center;
            }
        } 
    }  
    
    @media @desktop-m-down {
        .sider-map {
            &.show-mobile {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1000;
                width: 100% !important;
                max-width: 100% !important;
                height: 100%;
                transition: none;

                .mobile-map-wrapper {
                    overflow: auto;
                    overflow-y: scroll; /* has to be scroll, not auto */
                    -webkit-overflow-scrolling: touch;
                }

                #mobile-map-toggle {
                    top: initial;
                    bottom: 30px;
                    right: 30px;
                }

                #mobile-map-toggle button {
                    width: 50px;
                    height: 50px;
                }

                #mobile-map-toggle button i:before {
                    content: "\F379"; // check mark
                }
            }
        }
    }
}

.ivu-collapse > .ivu-collapse-item {
    border-top: 1px solid @border-color-light;
}

@media @desk-m-to-tablet {

    .main-layout .sider-filter {
        height: initial;
        margin-top: 200px;
        overflow: hidden;
    }

    .main-layout .sider-filter .sider-filter-wrapper {
        height: initial;
        padding: 15px 15px 70px 30px;
    }
}

@media @desktop-m {
    .listing .price > .cta > .ivu-btn { 
        font-size: 0.8em;
    }
}

@media @desktop-m-down {
    .main-layout {
        height: auto;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;        
        
        .content {
            height: auto;
            padding: 10px;
        }
    }
    
    .main-layout .sider-map {
        position: absolute;
        height: 200px;
        width: 100% !important;
        min-width: 100% !important;
    }

    #mobile-map-toggle {
        display: block !important;
        position: absolute;
        top: 150px;
        right: 10px;
    }

    #listing-container {
        margin-top: 210px;
        z-index: 102;
    }

    .main-layout .sider-filter .sider-filter-wrapper .map-toggle {
        display: none;
    }

    .searching {
        z-index: 103;
    }
}

@media @mobile-alt {

    .main-layout {
        .content {
            padding: 20px 10px;
        }
    }

    .main-layout .sider-map {
        height: 260px;
    }

    #listing-container {
        border-top-left-radius: 24px !important;
        border-top-right-radius: 24px !important;
    }
}
