@btn-prefix-cls: ~"@{css-prefix}btn";

.@{btn-prefix-cls} {
    .btn;
    .btn-default;

    &-long{
        width: 100%;
    }

    & > .ivu-icon + span, & > span + .ivu-icon{
        margin-left: 4px;
    }

    &-primary {
        .btn-primary;

        .@{btn-prefix-cls}-group:not(.@{btn-prefix-cls}-group-vertical) &:not(:first-child):not(:last-child) {
            border-right-color: @btn-group-border;
            border-left-color: @btn-group-border;
        }

        .@{btn-prefix-cls}-group:not(.@{btn-prefix-cls}-group-vertical) &:first-child {
            &:not(:last-child) {
                border-right-color: @btn-group-border;
                &[disabled] {
                    border-right-color: @btn-default-border;
                }
            }
        }

        .@{btn-prefix-cls}-group:not(.@{btn-prefix-cls}-group-vertical) &:last-child:not(:first-child),
        .@{btn-prefix-cls}-group:not(.@{btn-prefix-cls}-group-vertical) & + .@{btn-prefix-cls} {
            border-left-color: @btn-group-border;
            &[disabled] {
                border-left-color: @btn-default-border;
            }
        }

        .@{btn-prefix-cls}-group-vertical &:not(:first-child):not(:last-child) {
            border-top-color: @btn-group-border;
            border-bottom-color: @btn-group-border;
        }

        .@{btn-prefix-cls}-group-vertical &:first-child {
            &:not(:last-child) {
                border-bottom-color: @btn-group-border;
                &[disabled] {
                    border-top-color: @btn-default-border;
                }
            }
        }

        .@{btn-prefix-cls}-group-vertical &:last-child:not(:first-child),
        .@{btn-prefix-cls}-group-vertical & + .@{btn-prefix-cls} {
            border-top-color: @btn-group-border;
            &[disabled] {
                border-bottom-color: @btn-default-border;
            }
        }
    }

    //&-ghost {
    //    .btn-ghost;
    //}

    &-dashed{
        .btn-dashed;
    }

    &-text{
        .btn-text;
    }

    &-success {
        .btn-color(@success-color);
    }

    &-warning {
        .btn-color(@warning-color);
    }

    &-error {
        .btn-color(@error-color);
    }

    &-info {
        .btn-color(@info-color);
    }

    &-circle,
    &-circle-outline {
        .btn-circle(@btn-prefix-cls);
    }

    &:before {
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        background: #fff;
        opacity: 0.35;
        content: '';
        border-radius: inherit;
        z-index: 1;
        transition: opacity @transition-time;
        pointer-events: none;
        display: none;
    }

    &&-loading {
        pointer-events: none;
        position: relative;

        &:before {
            display: block;
        }
    }

    &-group {
        .btn-group(@btn-prefix-cls);
    }

    &-group-vertical {
        .btn-group-vertical(@btn-prefix-cls);
    }

    // The new ghost in 3.0
    &-ghost{
        color: #fff;
        background: transparent;
        &:hover{
            background: transparent;
        }
    }
    &-ghost&-dashed, &-ghost&-default{
        color: #fff;
        border-color: #fff;
        &:hover{
            color: tint(@primary-color, 20%);
            border-color: tint(@primary-color, 20%);
        }
    }
    &-ghost&-primary{
        color: @primary-color;
        &:hover{
            color: tint(@primary-color, 20%);
            background: fade(tint(@primary-color, 95%), 50%);
        }
    }
    &-ghost&-info{
        color: @info-color;
        &:hover{
            color: tint(@info-color, 20%);
            background: fade(tint(@info-color, 95%), 50%);
        }
    }
    &-ghost&-success{
        color: @success-color;
        &:hover{
            color: tint(@success-color, 20%);
            background: fade(tint(@success-color, 95%), 50%);
        }
    }
    &-ghost&-warning{
        color: @warning-color;
        &:hover{
            color: tint(@warning-color, 20%);
            background: fade(tint(@warning-color, 95%), 50%);
        }
    }
    &-ghost&-error{
        color: @error-color;
        &:hover{
            color: tint(@error-color, 20%);
            background: fade(tint(@error-color, 95%), 50%);
        }
    }

    &-ghost&-default[disabled], &-ghost&-dashed[disabled], &-ghost&-primary[disabled], &-ghost&-info[disabled], &-ghost&-success[disabled], &-ghost&-warning[disabled], &-ghost&-error[disabled]{
        background: transparent;
        color: fade(#000, 25%);
        border-color: @btn-disable-border;
    }
    &-ghost&-text[disabled]{
        background: transparent;
        color: fade(#000, 25%);
    }
}

a.@{btn-prefix-cls} {
    padding-top: 0.1px;
    line-height: @btn-height-base - 2px;

    &-large {
        line-height: @btn-height-large - 2px;
    }
    &-small {
        line-height: @btn-height-small - 2px;
    }
}
