@color-picker-prefix-cls: ~'@{css-prefix}color-picker';

.circle-dot(){
    width: 4px;
    height: 4px;
    box-shadow: 0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 0 0 1px 2px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    transform: translate(-2px, -2px);
}

.@{color-picker-prefix-cls} {
    display: inline-block;
    &-hide {
        display: none;
        &-drop {
            visibility: hidden;
        }
    }
    &-disabled {
        .disabled();
    }
    & > div:first-child:hover {
        .ivu-input {
            .hover();
        }
    }
    & > div:first-child.@{color-picker-prefix-cls}-disabled:hover {
        .ivu-input {
            border-color: tint(@input-border-color, 20%);
        }
    }
    & .@{select-dropdown-prefix-cls} {
        padding: 0;
    }

    &-input.ivu-input:focus{
        box-shadow: none;
    }
    &-focused {
        .active();
    }
    &-rel {
        line-height: 0;
    }
    &-color {
        width: 18px;
        height: 18px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
        border-radius: 2px;
        position: relative;
        top: 2px;
        div {
            width: 100%;
            height: 100%;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
            border-radius: 2px;
        }
        &-empty {
            background: #fff;
            overflow: hidden;
            text-align: center;
            i {
                font-size: 18px;
                vertical-align: baseline;
            }
        }
        &-focused {
            .active();
        }
    }
    &-large &-color {
        width: 20px;
        height: 20px;
        top: 1px;
        &-empty {
            i {
                font-size: 20px;
            }
        }
    }
    &-small &-color {
        width: 14px;
        height: 14px;
        top: 3px;
        &-empty {
            i {
                font-size: 14px;
            }
        }
    }

    &-picker {
        &-wrapper {
            padding: 8px 8px 0;
        }
        &-panel {
            width: 240px;
            margin: 0 auto;
            box-sizing: initial;
            position: relative;
        }
        &-hue-slider,
        &-alpha-slider {
            height: 10px;
            margin-top: 8px;
            position: relative;
        }
        &-colors {
            margin-top: 8px;
            overflow: hidden;
            border-radius: 2px;
            transition: border @transition-time @ease-in-out, box-shadow @transition-time @ease-in-out;
            &:focus {
                .active();
            }
            &-wrapper {
                display: inline;
                width: 20px;
                height: 20px;
                float: left;
                position: relative;
                &-color {
                    outline: 0;
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    margin: 2px;
                    cursor: pointer;
                    border-radius: 2px;
                    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
                }
                &-circle {
                    .circle-dot();
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    cursor: pointer;
                }
            }
        }
        .@{picker-prefix-cls}-confirm {
            margin-top: 8px;
        }
    }

    &-saturation {
        &-wrapper {
            width: 100%;
            padding-bottom: 75%;
            position: relative;
            //overflow: hidden;
            transition: border @transition-time @ease-in-out, box-shadow @transition-time @ease-in-out;

            &:focus {
                .active();
            }
        }
        &,
        &--white,
        &--black {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &--white {
            background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
        }
        &--black {
            background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
        }
        &-pointer {
            cursor: pointer;
            position: absolute;
        }
        &-circle {
            .circle-dot();
        }
    }

    &-hue {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);

        transition: border @transition-time @ease-in-out, box-shadow @transition-time @ease-in-out;

        &:focus {
            .active();
        }
        &-container {
            cursor: pointer;
            margin: 0 2px;
            position: relative;
            height: 100%;
        }
        &-pointer {
            z-index: 2;
            position: absolute;
        }
        &-picker {
            cursor: pointer;
            margin-top: 1px;
            width: 4px;
            border-radius: 1px;
            height: 8px;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
            background: #fff;
            transform: translateX(-2px);
        }
    }

    &-alpha {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;

        transition: border @transition-time @ease-in-out, box-shadow @transition-time @ease-in-out;

        &:focus {
            .active();
        }
        &-checkboard-wrap {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            border-radius: 2px;
        }
        &-checkerboard {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
        }
        &-gradient {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 2px;
        }
        &-container {
            cursor: pointer;
            position: relative;
            z-index: 2;
            height: 100%;
            margin: 0 3px;
        }
        &-pointer {
            z-index: 2;
            position: absolute;
        }
        &-picker {
            cursor: pointer;
            width: 4px;
            border-radius: 1px;
            height: 8px;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
            background: #fff;
            margin-top: 1px;
            transform: translateX(-2px);
        }
    }

    &-confirm {
        margin-top: 8px;
        position: relative;
        border-top: 1px solid @border-color-split;
        text-align: right;
        padding: 8px;
        clear: both;
        &-color {
            position: absolute;
            top: 11px;
            left: 8px;

            &-editable{
                top: 8px;
                right: 110px;
            }
        }
    }
}
