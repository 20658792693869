@tooltip-prefix-cls: ~"@{css-prefix}tooltip";
@tooltip-arrow: ~"@{tooltip-prefix-cls}-arrow";
@tooltip-max-width: 250px;
@tooltip-arrow-width: 5px;
@tooltip-distance: @tooltip-arrow-width - 1 + 4;

@tooltip-arrow-width-light: 7px;
@tooltip-distance-light: @tooltip-arrow-width-light - 1 + 4;
@tooltip-arrow-outer-width-light: (@tooltip-arrow-width-light + 1);
@tooltip-arrow-color: hsla(0,0%,85%,.5);

.@{tooltip-prefix-cls} {
    display: inline-block;

    &-rel{
        display: inline-block;
        position: relative;
        width: inherit;
    }

    &-popper{
        .popper(@tooltip-arrow, @tooltip-arrow-width, @tooltip-distance, @tooltip-bg);
    }
    &-light&-popper{
        .popper(@tooltip-arrow, @tooltip-arrow-width-light, @tooltip-distance-light, @tooltip-arrow-color);

        &[x-placement^="top"] .@{tooltip-arrow}:after {
            content: " ";
            bottom: 1px;
            margin-left: -@tooltip-arrow-width-light;
            border-bottom-width: 0;
            border-top-width: @tooltip-arrow-width-light;
            border-top-color: #fff;
        }

        &[x-placement^="right"] .@{tooltip-arrow}:after {
            content: " ";
            left: 1px;
            bottom: -@tooltip-arrow-width-light;
            border-left-width: 0;
            border-right-width: @tooltip-arrow-width-light;
            border-right-color: #fff;
        }

        &[x-placement^="bottom"] .@{tooltip-arrow}:after {
            content: " ";
            top: 1px;
            margin-left: -@tooltip-arrow-width-light;
            border-top-width: 0;
            border-bottom-width: @tooltip-arrow-width-light;
            border-bottom-color: #fff;
        }

        &[x-placement^="left"] .@{tooltip-arrow}:after {
            content: " ";
            right: 1px;
            border-right-width: 0;
            border-left-width: @tooltip-arrow-width-light;
            border-left-color: #fff;
            bottom: -@tooltip-arrow-width-light;
        }
    }

    &-inner{
        max-width: @tooltip-max-width;
        min-height: 34px;
        padding: 8px 12px;
        color: @tooltip-color;
        text-align: left;
        text-decoration: none;
        background-color: @tooltip-bg;
        border-radius: @border-radius-small;
        box-shadow: @shadow-base;
        white-space: nowrap;

        &-with-width{
            white-space: pre-wrap;
            text-align: justify;
            // 解决纯字母或数字不换行的问题
            word-wrap: break-word;
            word-break: break-all;
        }
    }

    &-light &-inner{
        background-color: #fff;
        color: @text-color;
    }

    &-arrow{
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &-light {
        .@{tooltip-arrow}{
            &:after{
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                border-color: transparent;
                border-style: solid;
                content: "";
                border-width: @tooltip-arrow-width-light;
            }
            border-width: @tooltip-arrow-outer-width-light;
        }
    }
}
