@transfer-prefix-cls: ~"@{css-prefix}transfer";
@transfer-item-prefix-cls: ~"@{css-prefix}transfer-list-content-item";

.@{transfer-prefix-cls} {
    position: relative;
    line-height: @line-height-base;

    &-list{
        display: inline-block;
        width: 180px;
        height: 210px;
        font-size: @font-size-base;
        vertical-align: middle;
        position: relative;
        padding-top: 35px;

        &-with-footer{
            padding-bottom: 35px;
        }

        &-header {
            padding: 8px 16px;
            background: @head-bg;
            color: @text-color;
            border: 1px solid @border-color-base;
            border-bottom: 1px solid @border-color-split;
            border-radius: @border-radius-base @border-radius-base 0 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            &-title{
                cursor: pointer;
            }

            & > span{
                padding-left: 4px;
            }

            &-count {
                margin: 0 !important;
                float: right;
            }
        }

        &-body{
            height: 100%;
            border: 1px solid @border-color-base;
            border-top: none;
            border-radius: 0 0 @border-radius-base @border-radius-base;
            position: relative;
            overflow: hidden;

            &-with-search{
                padding-top: 34px;
            }
            &-with-footer{
                border-radius: 0;
            }
        }

        &-content{
            height: 100%;
            padding: 4px 0;
            overflow: auto;

            &-item{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                & > span{
                    padding-left: 4px;
                }
            }

            &-not-found{
                display: none;
                text-align: center;
                color: @btn-disable-color;
            }
            li&-not-found:only-child{
                display: block;
            }
        }
        &-body-with-search &-content{
            padding: 6px 0 0;
        }

        &-body-search-wrapper{
            padding: 8px 8px 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        &-search{
            position: relative;
        }

        &-footer{
            border: 1px solid @border-color-base;
            border-top: none;
            border-radius: 0 0 @border-radius-base @border-radius-base;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            .clearfix();
        }
    }
    &-operation {
        display: inline-block;
        //overflow: hidden;
        margin: 0 16px;
        vertical-align: middle;

        .@{btn-prefix-cls} {
            display: block;
            min-width: @btn-circle-size-small;

            &:first-child {
                margin-bottom: 12px;
            }
        }
        .@{btn-prefix-cls}{
            span {
                i, span{
                    vertical-align: middle;
                }
            }
        }
    }
}
.select-item(@transfer-prefix-cls, @transfer-item-prefix-cls);
